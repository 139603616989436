import React from 'react';
import GranulatBilde from '../images/oppsamling-1.jpg';
import GranulatBilde2 from '../images/om-oss-bilde.jpg';
import Footer from './components/Footer';


function Oppsamling() {

    const articleStructuredData = {
        "@context": "http://schema.org",
        "@type": "Article",
        headline: "Oppsamling av granulat: Effektive løsninger for kunstgress",
        name: "Oppsamling av granulat",
        image: `${GranulatBilde2}`,
        articleBody: "Banefilter AS tilbyr effektive løsninger for oppsamling av granulat fra kunstgressbaner. Beskytt miljøet med våre kvalitetsprodukter.",
    }

        
  return (
    <>
        <main className='oppsamling'>
            <script type="application/ld+json">
                {JSON.stringify(articleStructuredData)}
            </script>
            <div className='about-heading'>
                <h1>Oppsamling av granulat: Effektive løsninger for kunstgress</h1>
            </div>
            <div className='content'>
                <div className='firstp-sec'>
                    <p>
                        Effektiv håndtering av gummigranulat er essensielt for å beskytte miljøet rundt kunstgressbaner. Bane lter AS tilbyr innovative løsninger for oppsamling av granulat, som hjelper idrettsanlegg, bedrifter og private med å redusere granulatsvinn. Våre lokalt produserte granulat lter og sluser sikrer at granulat ikke spres utenfor banene, noe som bidrar til en mer bærekraftig drift. Ved å implementere våre produkter, kan man enkelt opprettholde både banens kvalitet og miljøets helse.
                    </p>
                </div>
                <div className='secp-sec'>
                    <div className='left'>
                        <h2>Fordelene med effektiv oppsamling av granulat</h2>
                        <p>Effektiv oppsamling av granulat er avgjørende for å minimere miljøpåvirkningen fra kunstgressbaner. Ved å bruke våre spesialdesignede granulat lter og sluser, kan idrettsanlegg og private eiere redusere granulatsvinn betydelig. Våre løsninger, som inkluderer rensestasjoner og publikumssluser, er utviklet med tanke på både funksjonalitet og bærekraft. Vi sikrer at gummigranulat ikke sprer seg til omkringliggende områder, noe som beskytter både naturen og banens kvalitet. Våre produkter er produsert lokalt, noe som gir oss muligheten til å tilby skreddersydde løsninger som passer til enhver bane og behov. Ved å velge våre løsninger, investerer du i en mer bærekraftig fremtid for både idrettsanlegg og miljø.</p>
                    </div>
                    <div className='right'>
                        <img src={GranulatBilde} alt="Effektiv granulatoppsamling for miljøvennlig kunstgress"/>
                    </div>
                </div>
                <div className='thirdp-sec'>
                    <div className='left'>
                        <h3>Hvordan våre løsninger bidrar til effektiv oppsamling av granulat</h3>
                        <p>Våre spesialdesignede granulat lter og sluser er nøkkelen til effektiv oppsamling av granulat, noe som er avgjørende for å beskytte miljøet rundt kunstgressbaner. Ved å implementere våre løsninger, inkludert rensestasjoner og publikumssluser, kan både idrettsanlegg og private eiere redusere granulatsvinn betydelig. Dette bidrar til å forhindre spredning av gummigranulat til omkringliggende områder, og sikrer at både naturen og banens kvalitet bevares. Alle våre produkter er produsert lokalt, noe som gjør det mulig å tilby skreddersydde løsninger som passer til ethvert behov. Ved å velge våre løsninger, investerer du i en bærekraftig fremtid for både idrettsanlegg og miljøet, samtidig som du sikrer effektiv oppsamling av granulat.</p>
                    </div>
                    <div className='right'>
                        <img src={GranulatBilde2} alt="Innovative løsninger for å redusere granulatsvinn"/>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </>
  )
}

export default Oppsamling;